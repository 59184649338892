<template>
  <article class="container-fluid">
    <div v-show="showWizard">
      <product-wizard
        :initial-product="initialProduct"
        :production-units="productionUnits"
        :factory-data-sources="activeFactoryDataSources"
      />
    </div>
  </article>
</template>

<script>
import ProductWizard from "../components/product/ProductWizard";
import ProductService from "@/components/product/ProductService";
import ErrorHandling from "@/components/ErrorHandling";
import RouteService from "@/router/RouteService";
import { mapActions, mapGetters } from "vuex";
import ProductionUnitService from "@/components/productionunit/ProductionUnitService";
import DemoService from "@/components/DemoService";

export default {
  components: {
    ProductWizard,
  },
  data() {
    return {
      initialProduct: null,
      productionUnits: [],
      showWizard: false,
    };
  },
  computed: {
    ...mapGetters("navigation", ["activeFactoryId"]),
    ...mapGetters("user", ["email"]),
    ...mapGetters("devicesAdmin", ["activeFactoryDataSources"]),
    factoryId() {
      return this.activeFactoryId;
    },
    isFactoryIdDefined() {
      return this.factoryId != null && this.factoryId !== "";
    },
  },
  watch: {
    factoryId() {
      this.fetchProduct(this.$route.params.id);
    },
  },
  methods: {
    ...mapActions("operation", ["showOperationError"]),
    ...mapActions("devicesAdmin", ["fetchActiveFactoryDataSources"]),
    async fetchProduct(productId) {
      if (!this.isFactoryIdDefined) return;
      const includeDeleted = false;
      ProductService.getProduct(productId, includeDeleted)
        .then((response) => this.handleProductRetrievalResponse(response, productId))
        .catch((error) => this.handleProductRetrievalError(error.response));
    },
    handleProductRetrievalResponse(httpResponse, productId) {
      if (httpResponse.status === 200) {
        let product = httpResponse.data;
        product.description = DemoService.maskProductNameIfNecessary(this.email, product.description);
        if (product) {
          this.fetchProductionUnits(product);
        } else {
          this.showOperationError(this.$t("product.errors.noProductFound", { productId: productId }));
          this.$router.push(RouteService.toProducts());
        }
      }
    },
    handleProductRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
      this.$router.push(RouteService.toProducts());
    },
    async fetchProductionUnits(product) {
      ProductionUnitService.getProductionUnits(this.factoryId)
        .then((response) => this.handleProductionUnitsRetrievalResponse(response, product))
        .catch((error) => this.handleProductionUnitsRetrievalError(error.response));
    },
    async handleProductionUnitsRetrievalResponse(httpResponse, product) {
      if (httpResponse.status === 200) {
        this.productionUnits = httpResponse.data;
        this.productionUnits.forEach((x, index) => x.name = DemoService.maskProductionUnitNameIfNecessary(this.email, x.name, index + 1));

        await this.fetchActiveFactoryDataSources(this.activeFactoryId);

        if (product) {
          product.associated_production_units.forEach((puAssociation) => {
            let dataSources = this.activeFactoryDataSources
              .filter((ds) => ds.role === "in" || ds.role === "out")
              .filter((ds) => this.isForPU(ds, puAssociation));

            const productionUnit = this.productionUnits.find((pu) => pu.id === puAssociation.production_unit_id);

            puAssociation.conversion_factors = puAssociation.conversion_factors.map((cf) => {
              let ds = dataSources.find((ds) => ds.id === cf.data_source_id);
              return {
                deviceId: cf.device_id,
                dataSourceId: cf.data_source_id,
                dataSourceName: ds.description,
                role: ds.role,
                conversionFactor: cf.conversion_factor,
                unit: ds.unit ? ds.unit : productionUnit.converted_unit_name,
              };
            });
          });
        }
        this.initialProduct = product;
        this.showWizard = true;
      }
    },
    isForPU(ds, puAssociation) {
      return ds.production_unit_ids.includes(puAssociation.production_unit_id);
    },
    handleProductionUnitsRetrievalError(httpResponse) {
      this.showOperationError(ErrorHandling.buildErrorsMessages(httpResponse, this.getErrorMessage));
    },
    getErrorMessage(code) {
      return this.$t("common.errors.default", { code: code });
    },
  },
  async mounted() {
    const productId = this.$route.params.id;
    if (productId) {
      await this.fetchProduct(this.$route.params.id);
    } else if (this.isFactoryIdDefined) {
      await this.fetchProductionUnits(null);
    }
  },
};
</script>
