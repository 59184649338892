class ConversionUnitChainValidations {
  detectCircularUnitChain(unitConversions) {
    if (unitConversions.length === 0) return false;

    const lastUnit = unitConversions[unitConversions.length - 1].to_unit;

    // UI forces a continuous chain of units, so we can just check if there are two occurences of a unit in the chain for it to be circular
    const unitChain = unitConversions.map((conversion) => conversion.from_unit);
    unitChain.push(lastUnit);

    const seen = new Set();

    for (let unit of unitChain) {
      if (seen.has(unit)) {
        return true;
      }
      seen.add(unit);
    }

    return false;
  }

  // Detect that all production unit names have a valid chain to the product target base values (weight / length / volume )
  detectBrokenTargets(productionUnits, baseUnit, unitConversions) {
    if (!baseUnit)
      return {
        productionUnitName: null,
        missingUnits: [],
        baseUnit: null,
        isBroken: false,
      };

    for (let pu of productionUnits) {
      const missingUnits = this.getMissingUnits(pu.convertedUnitName, baseUnit, unitConversions);
      if (missingUnits.length > 0) {
        return {
          productionUnitName: pu.name,
          missingUnits: missingUnits,
          baseUnit: baseUnit,
          isBroken: true,
        };
      }
    }

    return {
      productionUnitName: null,
      missingUnits: [],
      baseUnit: null,
      isBroken: false,
    };
  }

  getMissingUnits(puConvertedUnitName, baseUnit, unitConversions) {
    if (baseUnit === puConvertedUnitName || baseUnit === null) {
      return [];
    } else {
      return this.getUnitsMissingFromUnitChain(puConvertedUnitName, baseUnit, unitConversions);
    }
  }

  // Returns which unit(s) need to be added to the chain to make it complete.
  getUnitsMissingFromUnitChain(startUnit, targetUnit, unitConversions) {
    const unitChain = this.getUnitChain(unitConversions);
    const unitsToAdd = [];
    !unitChain.includes(startUnit) ? unitsToAdd.push(startUnit) : null;
    !unitChain.includes(targetUnit) ? unitsToAdd.push(targetUnit) : null;
    return unitsToAdd;
  }

  // Returns the unit chain units
  getUnitChain(unitConversions) {
    if (unitConversions.length === 0) return [];

    const lastUnit = unitConversions[unitConversions.length - 1].to_unit;

    // UI forces a continuous chain of units, so we can just check if the start and target units are in the chain
    const unitChain = unitConversions.map((conversion) => conversion.from_unit);
    unitChain.push(lastUnit);
    return unitChain;
  }
}

export default new ConversionUnitChainValidations();
